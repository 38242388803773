import { api } from '../axios'

const SettingsService = (() => {
  const toggleEnableAfterCallTags = async () => {
    const res = await api.patch(`/organization/after-call-tags/toggle`)
    return res
  }

  return {
    toggleEnableAfterCallTags
  }
})()

export default SettingsService
