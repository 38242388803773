<template>
  <div class="settings">
    <h1 class="mt-0 mb-2">{{ $t('settings.title') }}</h1>

    <vuestic-accordion>
      <vuestic-collapse>
        <span slot="header" class="text-right">
          {{ $t('settings.accountSettings.title') }}
        </span>
        <div slot="body">
          <div style="padding: 10px;">
            <div class="mb-2 card">
              <router-link
                to="/settings/change-password"
                class="d-flex justify-content-between align-items-center"
              >
                <h3 class="m-0">
                  {{ $t('settings.accountSettings.changePassword.title') }}
                </h3>
              </router-link>
            </div>
            <div class="mb-2 card">
              <router-link
                to="/settings/update-organization-info"
                class="d-flex justify-content-between align-items-center"
              >
                <h3 class="m-0">
                  {{
                    $t('settings.accountSettings.updateOrganizationInfo.title')
                  }}
                </h3>
              </router-link>
            </div>
          </div>
        </div>
      </vuestic-collapse>
      <vuestic-collapse>
        <span slot="header"> {{ $t('settings.callSettings.title') }} </span>
        <div slot="body">
          <div style="padding: 10px;">
            <!-- <div class="mb-2 card">
              <router-link
                to="/tags"
                class="d-flex justify-content-between align-items-center"
              >
                <h3 class="m-0">
                  {{ $t('settings.callSettings.duringCall.title') }}
                </h3>
              </router-link>
            </div> -->
            <div class="mb-2 card">
              <h3 class="m-0">
                {{ $t('settings.callSettings.afterCall.title') }}
              </h3>

              <div class="mb-1 card">
                <div class="d-flex align-items-center">
                  <b-form-checkbox
                    size="lg"
                    v-model="enableCallSummary"
                    @change="save"
                    :disabled="enableCallSummaryLoading"
                  >
                    {{ $t('settings.callSettings.afterCall.callSummary') }}
                  </b-form-checkbox>
                  <half-circle-spinner
                    slot="loading"
                    :animation-duration="1500"
                    :size="25"
                    color="#74b9ff"
                    v-if="enableCallSummaryLoading"
                    class="mx-3"
                  />
                </div>
              </div>

              <div class="mb-1 card">
                <router-link
                  to="/tags"
                  class="d-flex justify-content-between align-items-center"
                >
                  <h3 class="m-0">
                    {{ $t('settings.callSettings.afterCall.tags') }}
                  </h3>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </vuestic-collapse>
    </vuestic-accordion>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import SettingsService from '../../../services/settings.service'
import HalfCircleSpinner from 'epic-spinners/src/components/lib/HalfCircleSpinner'

export default {
  name: 'Settings',
  components: {
    HalfCircleSpinner
  },
  computed: {
    ...mapGetters(['currentTenant'])
  },
  data() {
    return {
      enableCallSummary: true,
      enableCallSummaryLoading: false
    }
  },
  methods: {
    async save() {
      try {
        this.enableCallSummaryLoading = true
        const res = await SettingsService.toggleEnableAfterCallTags()
        console.log(res)
      } catch (error) {
        const errorMessage = error.response
          ? error.response.data.message
          : error.message
        this.toast(errorMessage)
      } finally {
        this.enableCallSummaryLoading = false
      }
    }
  },
  watch: {
    currentTenant: {
      immediate: true,
      handler() {
        if (!this.currentTenant) return
        this.enableCallSummary = this.currentTenant.enableAfterCallTags
      }
    }
  }
}
</script>

<style scoped>
.card {
  border: 1px solid #ccc;
  border-radius: 0.5rem;
  padding: 1rem;
}
</style>
